/* TODO: get rid of this */

/* global Stripe */
class PaymentService {
  /**
   * Inject external Stripe js library
   *
   * @return {Void}
   */
  static setStripeLibrary () {
    let stripe = document.createElement('script');
    stripe.setAttribute('src', 'https://js.stripe.com/v2/');
    stripe.async = true;
    document.head.appendChild(stripe);
  }

  static get months () {
    return [
      { m: '01' },
      { m: '02' },
      { m: '03' },
      { m: '04' },
      { m: '05' },
      { m: '06' },
      { m: '07' },
      { m: '08' },
      { m: '09' },
      { m: '10' },
      { m: '11' },
      { m: '12' }
    ];
  }

  static get years () {
    let thisYear = new Date().getFullYear();
    let expireYear = [];
    for (let i = 0; i <= 15; i++) {
      expireYear.push({ y: thisYear + i });
    }
    return expireYear;
  }

  /**
   * Retrieve a Stripe token
   * @param {object} card credit card details
   * @return {Promise}
   */
  static validateCreditCard (card) {
    if (card.exp_month) card.exp_month = parseInt(card.exp_month);
    if (card.exp_year) card.exp_year = parseInt(card.exp_year);

    return new Promise(function (resolve, reject) {
      if (!Stripe) {
        reject(new Error('Unable to connect to the payment processor. Please refresh and try again.'));
      } else {
        // Get stripe token for payment details
        Stripe.setPublishableKey(process.env.SPENV.STRIPE_KEY);
        Stripe.createToken(card, function (status, response) {
          resolve(response);
        });
      }
    });
  }
}

export default PaymentService;
