<template>
  <b-container class="py-5">
    <b-alert
      :show="hasAlert()"
      variant="danger"
      class="mb-4">
      <div class="text-center">
        {{ error }}
      </div>
    </b-alert>

    <div class="d-block d-sm-none text-center">
      <h2>Payment Information</h2>
    </div>

    <b-form>
      <!-- Credit card details -->
      <b-row class="py-2">
        <b-col
          cols="12"
          md="6"
          lg="7"
          class="card-body">
          <b-form-group>
            <small>
              <label for="card_number" :class="{ 'text-danger': errors.has('card_number') }">Credit Card*
                <span v-show="errors.has('card_number')"><font-awesome-icon icon="exclamation-triangle" size="sm" fixed-width /></span>
              </label>
            </small>
            <b-form-input
              id="card_number"
              key="card_number"
              v-model="card.number"
              v-validate="'required|credit_card'"
              data-vv-validate-on="blur|change"
              :class="{ 'is-invalid': errors.has('card_number') }"
              class="form-control w-100"
              type="text"
              name="card_number" />
          </b-form-group>
          <b-form-row>
            <b-col cols="12" md="6">
              <b-form-group>
                <small>
                  <label for="expDate" :class="{'text-danger': errors.has('exp_month') || errors.has('exp_year')}">Expiration*
                    <span v-show="errors.has('exp_month') || errors.has('exp_year')"><font-awesome-icon icon="exclamation-triangle" size="sm" fixed-width /></span>
                  </label>
                </small>
                <div class="form-row">
                  <b-col cols="6">
                    <b-form-select
                      id="exp_month"
                      key="exp_month"
                      v-model="card.exp_month"
                      v-validate="'required|numeric|max:2'"
                      data-vv-validate-on="blur|change"
                      :class="{ 'is-invalid': errors.has('exp_month') }"
                      class="form-control w-100 py-1"
                      placeholder="MM"
                      name="exp_month">
                      <option selected disabled value>
                        MM
                      </option>
                      <option v-for="month in months" :key="month.m" v-bind="months">
                        {{ month.m }}
                      </option>
                    </b-form-select>
                  </b-col>
                  <b-col cols="6">
                    <b-form-select
                      id="exp_year"
                      key="exp_year"
                      v-model="card.exp_year"
                      v-validate="'required|numeric|max:4'"
                      data-vv-validate-on="blur|change"
                      :class="{ 'is-invalid': errors.has('exp_year') }"
                      class="form-control w-100 py-1"
                      name="exp_year">
                      <option selected disabled value>
                        YYYY
                      </option>
                      <option v-for="year in years" :key="year.y" v-bind="years">
                        {{ year.y }}
                      </option>
                    </b-form-select>
                  </b-col>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group>
                <small>
                  <label for="cvc" :class="{ 'text-danger': errors.has('cvc') }">CVC*
                    <span v-show="errors.has('cvc')"><font-awesome-icon icon="exclamation-triangle" size="sm" fixed-width /></span>
                  </label>
                </small>
                <b-form-input
                  id="cvc"
                  key="cvc"
                  v-model="card.cvc"
                  v-validate="'required|numeric|max:4'"
                  data-vv-validate-on="blur|change"
                  :class="{ 'is-invalid': errors.has('cvc') }"
                  class="form-control w-100"
                  type="text"
                  name="cvc" />
              </b-form-group>
            </b-col>
          </b-form-row>
          <!-- Coupon -->
          <b-form-group>
            <small>
              <label for="coupon" :class="{ 'text-danger': coupon.error }">Promo code
                <span v-if="coupon.error"><font-awesome-icon icon="exclamation-triangle" size="sm" fixed-width /></span>
              </label>
            </small>
            <b-input-group class="w-100" :class="{ 'text-danger': error }">
              <b-input-group>
                <b-form-input
                  v-model="form.coupon"
                  type="text"
                  name="coupon"
                  :disabled="coupon.success"
                  :class="{ 'is-invalid': coupon.error }"
                  @change="coupon.error = null" />
                <b-input-group-append>
                  <b-button v-if="!coupon.success" variant="outline-primary" @click="validateCoupon(form.coupon)">
                    Apply
                  </b-button>
                  <b-button v-if="coupon.success" varient="outline-secondary" @click="removeCoupon()">
                    Remove
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-input-group>
            <p v-if="coupon.error" class="text-center text-danger">
              <span>{{ coupon.error }}</span>
            </p>
          </b-form-group>
          <p class="text-center">
            <small>*Required Fields</small>
          </p>
        </b-col>
        <!-- Order details -->
        <b-col
          v-if="user"
          cols="12"
          md="6"
          lg="5"
          class="card">
          <div class="card-body">
            <div class="text-center">
              <font-awesome-icon icon="rocket" size="2x" />
              <h4 v-if="user && user.first_name && user.last_name">
                {{ user.first_name }} {{ user.last_name }}'s Order
              </h4>
              <h4 v-else>
                {{ user.email }}'s Order
              </h4>
            </div>
            <!-- Inital plan price -->
            <b-row v-if="selectedPlan" class="py-3">
              <b-col cols="6">
                <span v-if="selectedPlan && selectedPlan.interval === 'year'"><b>Annual Membership</b></span>
                <span v-else><b>Monthly Membership*</b></span>
              </b-col>
              <b-col cols="6" class="text-right">
                <router-link to="/signup/plan">
                  <small>Change Plan</small>
                </router-link>
              </b-col>
            </b-row>
            <!-- Line items -->
            <b-row v-for="(line, index) in invoice.line_items" :key="index" class="py-1">
              <b-col cols="9">
                <small>{{ line.description }}</small>
              </b-col>
              <b-col cols="3" class="text-right">
                <small>{{ line.amount | formatCurrency }}</small>
              </b-col>
            </b-row>
            <!-- Order total -->
            <b-row class="py-1">
              <b-col cols="12" class="divider-line-full w-100" />
            </b-row>
            <b-row>
              <b-col cols="6">
                <small><b>Total:</b></small>
              </b-col>
              <b-col cols="6" class="text-right">
                <small><b>{{ invoice.total | formatCurrency }}</b></small>
              </b-col>
            </b-row>
            <!-- Remaining Balance (if any) -->
            <b-row v-if="invoice.remaining_credit > 0" class="py-1">
              <b-col cols="9">
                <small>Remaining Balance</small>
              </b-col>
              <b-col cols="3" class="text-right">
                <small>{{ invoice.remaining_credit | formatCurrency }}</small>
              </b-col>
            </b-row>
            <div class="text-center">
              <b-button
                variant="primary"
                type="submit"
                :disabled="!isFormValid"
                @click="validate">
                <span>Buy Now</span>
              </b-button>
            </div>
            <div v-if="selectedPlan && selectedPlan.interval === 'month'" class="text-center mt-2">
              <small>*Your credit card will be charged monthly. You can cancel your membership at any time.</small>
            </div>
            <div v-else class="text-center mt-2">
              <small>*Your credit card will be charged annually. You can cancel your membership at any time.</small>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import analyticsClickHandler from '@/utils/mixins/Analytics';
import axios from 'axios';
import PaymentService from '@/utils/payment.js';
import formatCurrency from '@/filters';
import FullPageLoader from '@/utils/mixins/FullPageLoader';
import redirectMixin from '@/utils/mixins/Redirect';


export default {
  name: 'Payment',
  mixins: [analyticsClickHandler, FullPageLoader, redirectMixin],
  data() {
    return {
      invoice: {
        total: 0,
        line_items: [],
        remaining_credit: 0
      },
      card: {
        number: null,
        exp_month: null,
        exp_year: null,
        cvc: null
      },
      form: {
        coupon: null
      },
      error: null,
      coupon: {
        success: false,
        error: null
      }
    };
  },
  computed: {
    plans () {
      return this.$store.getters['plans/getPlans'];
    },
    selectedPlan () {
      return this.$store.getters['plans/getSelectedPlan'];
    },
    returnURL () {
      return this.$store.getters['params/getReturnURL'];
    },
    months () {
      return PaymentService.months;
    },
    years () {
      return PaymentService.years;
    },
    isFormValid () {
      return Object.keys(this.veeFields).every(field => {
        return this.veeFields[field] && this.veeFields[field].valid;
      });
    }
  },
  created () {
    let planID = this.$route.params.plan_id;
    this.getInvoice(planID);
    PaymentService.setStripeLibrary();
  },
  mounted () {
    if (!this.isLoggedIn) {
      this.$router.push({name: 'SignupAccountInfo'});
    }
  },
  methods: {
    hasAlert() {
      return this.error ? true : false;
    },
    getInvoice (planId) {
      this.showFullPageLoadingCancelable(true);
      axios.get(this.SPENV.APP_URL + '/api/v1/app/membership/plan/' + planId + '/action', { withCredentials: true })
        .then(
          (response) => {
            this.invoice.total = response.data.total;
            this.invoice.line_items = response.data.lines;
            this.invoice.remaining_credit = response.data.remaining_account_credit;
          })
        .catch(
          (error) => {
            console.log(error);
          })
        .then(
          () => {
            this.showFullPageLoading(false);
          });
    },
    validate(event) {
      event.preventDefault();
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            // if form is valid, execute captcha which will trigger signup callback
            this.submitOrder();
          } else {
          // form is invalid - do nothing
          }
        });
    },
    validateCoupon(coupon) {
      console.log('!!! - Coupon: ', coupon);
      if (!coupon) {
        return '';
      }
      this.showFullPageLoadingCancelable(true);
      axios
        .get(this.SPENV.APP_URL + '/api/v1/app/membership/plan/' + this.selectedPlan.id + '/coupon/' + this.form.coupon + '', { withCredentials: true }).then(
          (response) => {
            this.invoice.total = response.data.total;
            this.invoice.line_items = response.data.lines;
            this.invoice.remaining_credit = response.data.remaining_account_credit;
            this.coupon.success = true;
          })
        .catch(
          (error) => {
            this.coupon.error = error.data ? error.data : 'This coupon is not valid.';
          })
        .then(() => {
          this.showFullPageLoading(false);
        });
    },
    removeCoupon() {
      // Update invoice items when removing coupons jhamm 3/28/18
      this.getInvoice(this.selectedPlan.id);
      // Reset local coupon vars jhamm 3/28/18
      this.coupon = {
        success: false,
        error: null
      };
      this.form.coupon = null;
    },
    submitOrder() {
      this.errors.clear();
      this.error = null;
      let isNewUser = !this.user.membership;
      this.showFullPageLoadingCancelable(true);
      PaymentService.validateCreditCard({
        number: this.card.number,
        exp_month: this.card.exp_month,
        exp_year: this.card.exp_year,
        cvc: this.card.cvc
      }).then(
        (response) => {
          // Stripe token error
          if (response.error) {
            this.error = response.error.message;
            this.showFullPageLoading(false);
            // Process payment and enroll in membership
          } else {
            // Needed for post request
            // 1. stripe token
            // 2. plan id (7 or 8)
            // 3. coupon (optional)
            let form = {};
            form.stripe_token = response.id;
            form.coupon = this.form.coupon;
            form.plan_id = this.selectedPlan.id;
            axios.post(
              this.SPENV.APP_URL + '/api/v1/app/membership/subscribe',
              form,
              {
                withCredentials: true,
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
              }
            ).then(
              (response) => {
                this.showFullPageLoading(false);
                if (isNewUser) {
                  this.sendNewMembershipEvent(response);
                }
                this.redirectUrl(this.returnURL || this.SPENV.APP_URL);
              })
              .catch(
                (error) => {
                  console.log(error);
                  if (_.has(error, 'response.data.message')) {
                    this.error = error.response.data.message;
                    this.errors.add({
                      field: error.response.data.param,
                      msg: error.response.data.message
                    });
                  }
                  this.showFullPageLoading(false);
                });
          }
        },
        (error) => {
          this.error = error.message;
          this.showFullPageLoading(false);
        }
      );
    },

    /**
     * Send GTM Membership event
     */
    sendNewMembershipEvent(response) {
      let category = 'Membership';
      let action;
      let label = 'userId: ' + this.user.id + ', orderId: ' + response.data.order_history[0].id;
      let value = response.data.order_history[0].total;
      if (this.selectedPlan && this.selectedPlan.interval === 'month') {
        action = 'Monthly Subscription';
      } else {
        action = 'Annual Subscription';
      }
      this.analyticsClickEvent(category, action, label, value);
    }
  }
};
</script>

<style scoped></style>
